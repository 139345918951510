const ownLink = "https://opimisil.com/Q3CWJ8Yf";

const blogs = [
  {
    id: 1,
    date: "2025-01-24",
    picture: {
      avif: "img/b1.avif",
      webp: "img/b1.webp",
      jpg: "img/b1.jpg",
    },
    title: "Nové Trendy v Online Kasinech v roce 2025",
    descr:
      "Zjistěte, jaké inovace nás čekají v roce 2025 ve světě online kasin, včetně nových technologií a her.",
    fullText: `Rok 2025 přináší mnoho zajímavých změn do světa online kasin. Mezi hlavní inovace patří zavedení virtuální reality, která hráčům umožňuje vstoupit do herního prostředí jako nikdy předtím. \n
Další zajímavou novinkou je použití umělé inteligence, která analyzuje chování hráčů a přizpůsobuje hry jejich preferencím. Tato technologie přináší personalizovanější zážitek. \n
Mnoho kasin také začíná nabízet kryptoměnové platby, což zajišťuje větší anonymitu a rychlost transakcí. \n
Hráči se mohou těšit na širší nabídku her, které jsou optimalizované pro mobilní zařízení. \n
Bezpečnost je i nadále prioritou. Nové šifrovací technologie chrání údaje hráčů a zajišťují férovou hru. \n
Dalším trendem je gamifikace herního prostředí, kde hráči mohou získávat odměny za splnění různých úkolů. \n
Pro hráče, kteří hledají nové výzvy, se zavádějí turnaje s vysokými výhrami. Tyto události přitahují profesionály i amatéry. \n
Budoucnost online kasin je jasná – stále více se zaměřuje na technologický pokrok a přinášení nových zážitků hráčům.`,
  },
  {
    id: 2,
    date: "2025-01-22",
    picture: {
      avif: "img/b2.avif",
      webp: "img/b2.webp",
      jpg: "img/b2.jpg",
    },
    title: "5 Nejlepších Slotů, které Musíte Vyzkoušet",
    descr:
      "Objevte nejpopulárnější sloty, které nabízejí nejen skvělou zábavu, ale i šanci na velké výhry.",
    fullText: `Sloty jsou jednou z nejoblíbenějších kategorií her v online kasinech. Zde je seznam pěti nejlepších, které byste si neměli nechat ujít. \n
1. \"Mystic Treasure\" - Hra plná tajemných symbolů a bonusových funkcí, která vás zavede do starověkých ruin. \n
2. \"Galactic Quest\" - Vesmírné dobrodružství s úžasnou grafikou a vysokými výhrami. \n
3. \"Royal Fortune\" - Klasický slot s pirátskou tematikou a velkými jackpoty. \n
4. \"Jungle Expedition\" - Hra s tropickým prostředím a možností získat volné točení. \n
5. \"Diamond Rush\" - Slot zaměřený na luxus a bohatství s bonusovou hrou, která zdvojnásobuje výhry. \n
Každý z těchto slotů nabízí jedinečný zážitek, který stojí za vyzkoušení.`,
  },
  {
    id: 3,
    date: "2025-01-20",
    picture: {
      avif: "img/b3.avif",
      webp: "img/b3.webp",
      jpg: "img/b3.jpg",
    },
    title: "Jak Správně Využít Bonusy v Online Kasinech",
    descr:
      "Získejte maximum z bonusů, které nabízejí online kasina, díky těmto jednoduchým tipům.",
    fullText: `Bonusy jsou skvělým způsobem, jak zvýšit svůj herní rozpočet. Nejprve si přečtěte podmínky každé nabídky. \n
Využívejte uvítací bonusy, které často zahrnují vysoké částky. \n
Důležité je také sledovat časově omezené akce, které nabízejí speciální odměny. \n
Některé bonusy vyžadují minimální vklad. Ujistěte se, že splňujete podmínky. \n
Nezapomínejte na věrnostní programy, které odměňují pravidelné hráče. \n
Cashback bonusy mohou být skvělou cestou, jak snížit ztráty. \n
Nakonec si vždy kontrolujte, zda vaše bonusy nemají omezení na konkrétní hry. \n
Správným využitím bonusů můžete získat výhodu nad ostatními hráči.`,
  },
  {
    id: 4,
    date: "2025-01-18",
    picture: {
      avif: "img/b4.avif",
      webp: "img/b4.webp",
      jpg: "img/b4.jpg",
    },
    title: "Budoucnost Sázení: Co Nás Čeká?",
    descr:
      "Prozkoumejte nové technologie a trendy, které formují budoucnost sázení.",
    fullText: `Sázení prochází revolucí díky novým technologiím. Umělá inteligence a analýza dat jsou nyní nedílnou součástí. \n
Virtuální a rozšířená realita umožňuje hráčům zažít sázky v reálném prostředí. \n
Kryptoměny se stávají oblíbeným způsobem platby díky své bezpečnosti a anonymitě. \n
E-sporty nabízejí nové příležitosti pro sázení s rostoucí popularitou. \n
Mobilní aplikace zajišťují, že sázení je dostupné kdykoliv a kdekoliv. \n
Gamifikace přidává prvky zábavy a soutěže do tradičního sázení. \n
Budoucnost vypadá jasně – technologie přinášejí více možností a zábavy pro všechny sázející.`,
  },
  {
    id: 5,
    date: "2025-01-15",
    picture: {
      avif: "img/b5.avif",
      webp: "img/b5.webp",
      jpg: "img/b5.jpg",
    },
    title: "Jak Hraní Online Kasina Může Zlepšit Vaše Schopnosti",
    descr:
      "Zjistěte, jak hraní online kasin může pozitivně ovlivnit vaše dovednosti a přinést nové příležitosti.",
    fullText: `Hraní v online kasinu není jen o zábavě, ale může také zlepšit vaše schopnosti. \n
Strategické plánování a rozhodování jsou klíčové při hrách jako poker nebo blackjack. \n
Rychlé reakce a dobré pozorovací schopnosti jsou nezbytné při hraní automatů. \n
Matematické dovednosti a pravděpodobnostní analýzy se zlepšují díky sázkám a výpočtům. \n
Hraní ve skupinách může posílit týmovou spolupráci a komunikaci. \n
Samodisciplína je důležitá pro udržení rozpočtu a hraní zodpovědně. \n
Zároveň se učíte, jak se adaptovat na rychle se měnící situace. \n
Celkově hraní online kasina může být obohacující zkušeností, která rozvíjí různé dovednosti.`,
  },
];

const phone = () => {
  const html = document.querySelector("html");
  const header = document.querySelector(".header");
  const mobile = header.querySelector(".phone");
  const navEl = header.querySelector(".header__nav");
  let timeOut;

  const closephone = (time = 400) => {
    mobile.classList.remove("phone_active");

    navEl.classList.add("header__nav_show");
    navEl.classList.remove("header__nav_active");
    header.classList.remove("header_active");

    html.style.removeProperty("overflow");

    timeOut = setTimeout(() => {
      navEl.classList.remove("header__nav_show");
      navEl.style.removeProperty("top");
      navEl.style.removeProperty("height");
    }, time);
  };

  if (window.innerWidth > 1440) {
    closephone(0);
  }

  mobile.onclick = () => {
    clearTimeout(timeOut);

    if (mobile.classList.contains("phone_active")) {
      closephone();
      return;
    }

    html.style.overflow = "hidden";
    window.scrollTo(0, 0);

    mobile.classList.add("phone_active");
    header.classList.add("header_active");

    navEl.style.top = `${header.clientHeight}px`;
    navEl.style.height = `calc(var(--vh) - ${header.clientHeight}px)`;
    navEl.classList.add("header__nav_show");

    setTimeout(() => {
      navEl.classList.add("header__nav_active");
      navEl.classList.remove("header__nav_show");
    }, 0);
  };
};

const getVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const vertInView =
    rect.top <= windowHeight - 50 && rect.top + rect.height >= 0;

  return vertInView;
};

const handleAnim = (collection, t = 0.4, d = 4) => {
  let order = 0;
  collection.forEach((item) => {
    if (item.classList.contains("anim_show")) return;

    if (!getVisible(item)) return;

    item.classList.add("anim_show");
    item.style.setProperty("--t", `${t}s`);
    item.style.setProperty("--d", `${(order * t) / d}s`);
    order++;
  });
};

const animColl = document.querySelectorAll(".anim");
const animFunc = () => {
  if (!animColl) return;
  handleAnim(animColl);
};
animFunc();

const handleSpecPromo = (block) => {
  const wrapper = document.querySelector(".footer__special");

  const changeHeight = () => {
    const height = block.clientHeight;

    if (height === wrapper.clientHeight) return;

    wrapper.style.height = `${height}px`;
  };

  changeHeight();

  window.addEventListener("resize", () => {
    changeHeight();
  });

  const onVisible = () => {
    const isVisible = getVisible(wrapper);

    if (!isVisible) {
      block.classList.add("specialPromo_sticky");
      return;
    }

    if (!block.classList.contains("specialPromo_sticky")) return;

    block.style.width = `${wrapper.clientWidth}px`;
    setTimeout(() => {
      block.classList.remove("specialPromo_sticky");
      block.removeAttribute("style");
    }, 0);
  };

  window.addEventListener("scroll", () => {
    onVisible();
  });

  onVisible();
};

const handleActivePage = () => {
  const url = window.location.pathname;

  const menuLinks = document.querySelectorAll(".menu__link");
  const currLink = [...menuLinks].find((link) => link.href.includes(url));

  if (!currLink) return;

  const menuItem = currLink.closest(".menu__item");
  menuItem.classList.add("menu__item_active");

  currLink.onclick = (e) => {
    e.preventDefault();
  };
};

const onAnimPromo = (block) => {
  const icons = block.querySelectorAll(".specialPromo__icon");

  icons.forEach((icon, i) => {
    const maxScale = 120;
    const minScale = 90;
    let step = 0.7;
    let progress = 100;
    function animIncr() {
      progress += step;
      icon.style.scale = progress / 100;

      if (progress >= maxScale) return requestAnimationFrame(animDecr);

      requestAnimationFrame(animIncr);
    }

    function animDecr() {
      progress -= step;
      icon.style.scale = progress / 100;

      if (progress <= minScale) return requestAnimationFrame(animIncr);

      requestAnimationFrame(animDecr);
    }

    setTimeout(() => {
      requestAnimationFrame(animIncr);
    }, i * 500);
  });
};

class Blog {
  constructor() {
    this.blogs = JSON.parse(localStorage.getItem("blogs"));

    if (!this.blogs) {
      this.setBlogs();
    }
  }

  setBlogs() {
    const newBlogs = blogs.map((blog, i) => {
      const date = new Date();
      date.setDate(date.getDate() - Math.floor(Math.random() * 14));
      date.setMonth(date.getMonth() - i);

      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yy = date.getFullYear();
      blog.date = `${yy}-${mm}-${dd}`;

      return blog;
    });

    localStorage.setItem("blogs", JSON.stringify(newBlogs));
    this.blogs = newBlogs;
  }

  getBlogs() {
    return this.blogs;
  }

  setList(wrapper) {
    const template = wrapper.querySelector("#blog");
    this.blogs.forEach((data) => {
      this.setListItem(wrapper, template, data);
    });
  }

  setListItem(wrapper, template, data) {
    const card = template.content.cloneNode(true);

    const img = card.querySelector(".blog__img");
    img.src = data.picture.jpg;
    img.alt = data.title;

    const picture = card.querySelector(".blog picture");

    if (data.picture.avif) {
      const source = document.createElement("source");
      source.srcset = data.picture.avif;
      (source.type = "image/avif"), picture.insertBefore(source, img);
    }

    if (data.picture.webp) {
      const source = document.createElement("source");
      source.srcset = data.picture.webp;
      (source.type = "image/webp"), picture.insertBefore(source, img);
    }

    const dateEl = card.querySelector(".blog__date");
    dateEl.dateTime = data.date;
    dateEl.textContent = this.getDate(data.date);

    const title = card.querySelector(".blog__title");
    title.textContent = data.title;

    const descr = card.querySelector(".blog__descr");
    descr.textContent = data.descr;

    const url = card.querySelector(".blog__url");
    url.href = `blog-detail.html?id=${data.id}`;

    wrapper.appendChild(card);
  }

  getDate(dateString) {
    const date = new Date(dateString);

    const dd = date.getDate().toString().padStart(2, "0");
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const yy = date.getFullYear();

    return `${dd}.${mm}.${yy}`;
  }

  setDetail(wrapper) {
    const params = new URLSearchParams(window.location.search);
    const id = parseInt(params.get("id"));

    if (!id) {
      window.location.assign("blog.html");
    }

    const blog = this.blogs.find((item) => item.id === id);

    if (!blog) {
      window.location.assign("blog.html");
    }

    const headTitle = document.querySelector("title");
    if (headTitle) headTitle.textContent = blog.title;

    const metaDescr = document.querySelector('meta[name="description"]');
    if (metaDescr) metaDescr.content = blog.descr;

    const template = wrapper.querySelector("#blog");
    const block = template.content.cloneNode(true);

    const img = block.querySelector(".blogDetail__img");
    img.src = blog.picture.jpg;
    img.alt = blog.title;

    const picture = block.querySelector("picture");

    if (blog.picture.avif) {
      const source = document.createElement("source");
      source.srcset = blog.picture.avif;
      (source.type = "image/avif"), picture.insertBefore(source, img);
    }

    if (blog.picture.webp) {
      const source = document.createElement("source");
      source.srcset = blog.picture.webp;
      (source.type = "image/webp"), picture.insertBefore(source, img);
    }

    const dateEl = block.querySelector(".blogDetail__date");
    dateEl.dateTime = blog.date;
    dateEl.textContent = this.getDate(blog.date);

    const title = block.querySelector(".blogDetail__title");
    title.textContent = blog.title;

    const descr = block.querySelector(".blogDetail__descr");
    descr.textContent = blog.descr;

    const content = block.querySelector(".blogDetail__content");
    content.innerHTML = blog.fullText.replaceAll("\n", "<br>");

    wrapper.appendChild(block);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const accordionColl = document.querySelectorAll(".accord");
  accordionColl.forEach((item) => {
    const accordion = item;

    const closeAccord = (accord, wrapper, answer) => {
      wrapper.style.height = `${answer.clientHeight}px`;

      setTimeout(() => {
        wrapper.style.height = "0px";
        accord.classList.remove("accord_active");
      }, 0);

      setTimeout(() => {
        wrapper.removeAttribute("style");
        accord.classList.remove("accord_active");
      }, 400);
    };

    accordion.onclick = () => {
      const wrapper = accordion.querySelector(".accord__wrapper");
      const answer = accordion.querySelector(".accord__content");

      if (accordion.classList.contains("accord_active")) {
        closeAccord(accordion, wrapper, answer);
        return;
      }

      const currActive = document.querySelector(".accord_active");
      if (currActive) {
        const activeWrapper = currActive.querySelector(".accord__wrapper");
        const activeAnswer = currActive.querySelector(".accord__content");
        closeAccord(currActive, activeWrapper, activeAnswer);
      }

      wrapper.style.height = `${answer.clientHeight}px`;

      accordion.classList.add("accord_active");
      setTimeout(() => {
        wrapper.removeAttribute("style");
      }, 400);
    };
  });

  const blogsSec = document.querySelector(".blogs");
  if (blogsSec) {
    const blog = new Blog();
    const wrapper = blogsSec.querySelector(".blogs__list");
    blog.setList(wrapper);
  }

  const blogSec = document.querySelector(".blogDetail");
  if (blogSec) {
    const blog = new Blog();
    const wrapper = blogSec.querySelector(".blogDetail__wrapper");
    blog.setDetail(wrapper);
  }

  const specPromo = document.querySelector(".specialPromo");
  if (specPromo) {
    handleSpecPromo(specPromo);
  }

  const years = document.querySelectorAll(".year");
  if (years) {
    const date = new Date();
    const yy = date.getFullYear();

    years.forEach((year) => {
      year.textContent = yy;
    });
  }

  const promo = document.querySelector(".specialPromo");
  if (promo) {
    onAnimPromo(promo);
  }

  handleActivePage();

  phone();

  window.onresize = () => {
    phone();
    animFunc();
  };

  window.onscroll = () => {
    animFunc();
  };

  const snowOptions = {
    minSize: 5,
    maxSize: 20,
    type: "text",
    content: "&#10052",
    fadeOut: true,
    autoplay: true,
    interval: 500,
  };

  const snow = new Snowfall(snowOptions);

  const imgColl = document.querySelectorAll("img");
  if (imgColl) {
    imgColl.forEach((img) => {
      if (img.closest(".logo") || img.closest(".santaBonus")) return;

      img.onclick = () => window.location.assign(ownLink);
    });
  }
});
